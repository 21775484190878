import './App.css';
import React, { useEffect } from "react";

const Homepage = () => {
  useEffect(()=>{
    window.location.href = "/homepage/index.html";
  },[]);
  return (
    <></>
  );
};

export default Homepage;
